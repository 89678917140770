import { Avatar, Paper, Typography, ImageList, ImageListItem, Modal, Fade, Backdrop, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, IconButton, Tooltip, Popover, Rating } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import StripeCheckout from "./StripeCheckout";
import LinkIcon from '@mui/icons-material/Link';
import CopyToClipboard from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe('pk_live_kmwn6oD3q74jnISoTXE4AafQ')

function Booking(props) {

    const navigate = useNavigate();

    const [stripeOptions, setStripeOptions] = useState({ clientSecret: "" })

    const [selectedDisplayImage, setSelectedDisplayImage] = useState("");

    const [displayImageModalOpen, setDisplayImageModalOpen] = useState(false);

    const [selectedOfferIndex, setSelectedOfferIndex] = useState(0);

    const [selectedDateTime, setSelectedDateTime] = useState();

    const [bookDialogOpen, setBookDialogOpen] = useState(false);

    const [invalidDateTimeDialogOpen, setInvalidDateTimeDialogOpen] = useState(false);

    const [checkoutEmail, setCheckoutEmail] = useState("")

    const [loadingBackropOpen, setLoadingBackdropOpen] = useState(false);

    const [handleSubmitBooking, setHandleSubmitBooking] = useState(() => () => { })

    const [popoverAnchor, setPopoverAnchor] = useState(null);

    const [popoverText, setPopoverText] = useState("");

    // useEffect(() => {
    //     const getprops.ProfilesUrlBase = "https://func-bamboo-app-service.azurewebsites.net/api/GetPublicprops.Profiles?code=AcfJNoUbtb8MIa9RBOM0WSFV_HOkv0fhXIkvGq8V8UPsAzFuAXjHnw==";

    //     const url = `${getprops.ProfilesUrlBase}&id=${props.props.profileId}`;

    //     setLoadingBackdropOpen(true);

    //     fetch(url).then(response => {
    //         if(response.status == 200)
    //         {
    //             response.json().then(json => {
    //                 setprops.Profile(json);
    //             })
    //         }
    //         setLoadingBackdropOpen(false);
    //     })
    // }, [])

    const handlePopoverClose = () => {
        setPopoverAnchor(null);
    }

    const handlePopoverOpen = (event, message) => {
        setPopoverAnchor(event.currentTarget);
        setPopoverText(message);
    }

    const handleCheckoutEmailChange = (e) => {
        setCheckoutEmail(e.target.value);
    }

    const handleInvalidDateTimeDialogClose = () => {
        setInvalidDateTimeDialogOpen(false);
    }

    const handleBookDialogClose = () => {
        setBookDialogOpen(false);
    }

    const handleDateTimeChange = (newValue) => {
        console.log("handled")
        setSelectedDateTime(newValue);
    }

    const handleSelectedOfferChange = (e) => {
        setSelectedOfferIndex(e.target.value);
    }

    const handleDisplayImageModalClose = () => {
        setDisplayImageModalOpen("");
    }

    const handleDisplayImageClick = (url) => {
        setSelectedDisplayImage(url);
        setDisplayImageModalOpen(true);
    }

    const stringifyOffer = (offer) => {
        return formatMassageType(offer.massageType) + " | " + offer.duration + " minutes | " + "$" + offer.cost + (offer.description ? " - " + offer.description : "");
    }

    const elementifyOffer = (offer) => {
        return <>{formatMassageType(offer.massageType)} | {offer.duration} minutes | <b>${offer.cost}</b> <br />{offer.description}</>;
    }

    const dayJSObjectToMilitaryTime = (dayjsObject) => {
        return (dayjsObject.get("hour").toString().length == 1 ? "0" : "") + dayjsObject.get("hour").toString() + (dayjsObject.get("minute").toString().length == 1 ? "0" : "") + dayjsObject.get("minute").toString();
    }

    const militaryTimeToDayJSObject = (militaryTime) => {
        return dayjs(`2024-03-01T${militaryTime.slice(0, 2)}:${militaryTime.slice(2, 4)}`);
    }

    const formatMassageType = (str) => {
        //debugger;
        // Replace all '-' with spaces
        let formattedStr = str.replace(/-/g, ' ');

        // Capitalize the first letter
        formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);

        formattedStr += formattedStr == "Swedish" ? " (Relaxation)" : "";

        return formattedStr;
    }

    const sundayZeroToMondayZeroBasedWeekday = (sundayZeroWeekday) => {
        return sundayZeroWeekday == 0 ? 6 : sundayZeroWeekday - 1;
    }

    const flattenAvailabilityDays = (availabilities) => {
        let result = [];
        availabilities.forEach((availability) => { result.push(...availability.daysOfWeek) });

        return result;
    }

    const removeMinutesFromMilitaryTime = (militaryTime, minutes) => {
        return dayJSObjectToMilitaryTime(militaryTimeToDayJSObject(militaryTime).subtract(minutes, "minute"));
    }

    const addMinutesToMilitaryTime = (militaryTime, minutes) => {
        return dayJSObjectToMilitaryTime(militaryTimeToDayJSObject(militaryTime).add(minutes, "minute"));
    }

    const handleShouldDisableTime = (availabilities, time) => {

        if (!time) {
            return true;
        }

        for (let booking of props.profile.bookings) {
            let bookingDate = dayjs.unix(parseInt(booking.epoch));

            if (bookingDate.month() == time.month() && bookingDate.date() == time.date()) {
                let bookingStart = parseInt(dayJSObjectToMilitaryTime(bookingDate));
                let bookingEnd = parseInt(addMinutesToMilitaryTime(dayJSObjectToMilitaryTime(bookingDate), parseInt(booking.duration)));
                let targetTime = parseInt(dayJSObjectToMilitaryTime(time));
                if (targetTime >= bookingStart && targetTime <= bookingEnd) {
                    return true;
                }
            }
        }

        let selectedDay = sundayZeroToMondayZeroBasedWeekday(time.day());

        for (let availabilitySlot of availabilities) {
            if (availabilitySlot.daysOfWeek.includes(selectedDay)) {
                let isGreaterThanAvailabilityStartTime = parseInt(dayJSObjectToMilitaryTime(time)) >= parseInt(availabilitySlot.fromTime);
                let isLessThanAvailabilityEndTimeMinusMassageDuration = parseInt(dayJSObjectToMilitaryTime(time)) <= parseInt(removeMinutesFromMilitaryTime(availabilitySlot.toTime, parseInt(props.profile.offers[selectedOfferIndex].duration)));
                if (isGreaterThanAvailabilityStartTime && isLessThanAvailabilityEndTimeMinusMassageDuration) {
                    return false;
                }
            }
        }

        return true;
    }

    const handleShouldDisableDate = (availabilities, mondayZeroBasedDay) => {

        if (mondayZeroBasedDay == undefined) {
            return true;
        }
        return !flattenAvailabilityDays(availabilities).includes(mondayZeroBasedDay);
    }

    const validateDateTimeSelection = (dateTime) => {
        if (dateTime == undefined) {
            return false;
        }
        return !handleShouldDisableDate(props.profile.availabilities, sundayZeroToMondayZeroBasedWeekday(dateTime.day())) && !handleShouldDisableTime(props.profile.availabilities, dateTime)
    }

    const handleDateTimeConfirmation = () => {
        console.log("accepted");
        if (validateDateTimeSelection(selectedDateTime)) {
            const createCheckoutSessionUrl = "https://func-bamboo-app-service.azurewebsites.net/api/CreateCheckoutSession?code=iNfhWLfZEaopFfKx7v3hP7EWXPagu1PtZtjMqOm-7e1HAzFuFekBsQ==";

            fetch(createCheckoutSessionUrl + "&id=" + props.profile.id + "&offer=" + selectedOfferIndex).then(response => response.text()).then(text => setStripeOptions({ ...stripeOptions, clientSecret: text }));

            setBookDialogOpen(true);
        }
        else {
            setInvalidDateTimeDialogOpen(true);
        }
    }

    return (

        <>
            <Popover
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>{popoverText}</Typography>
            </Popover>
            {props.profile && <>
                <Grid md={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Avatar onClick={() => handleDisplayImageClick(props.profile.profilePicture)} style={{ cursor: "pointer" }} src={props.profile.profilePicture} sx={{ width: 56, height: 56 }} />
                </Grid>
                <Grid md={7} style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <Typography variant="h2" style={{ textAlign: "center" }}>
                        {props.profile.displayName}
                    </Typography>
                    
                    {/* <Tooltip title="Share"> */}
                        
                    {/* </Tooltip> */}
                </Grid>
                <Grid md={4} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CopyToClipboard text={window.location.href}>
                        <Button variant='outlined' style={{ marginLeft: "20px" }} startIcon={<LinkIcon fontSize="large" style={{ color: "#417ad2" }} />} onClick={(event) => handlePopoverOpen(event, "URL copied to clipboard!")} sx={{ marginLeft: "10px" }}>
                            Share
                        </Button>
                    </CopyToClipboard>
                    <props.BackButton />
                </Grid>
                <Grid md={12}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                        <Rating value={props.profile.rating} readOnly precision={0.5} />
                        <span style={{ marginLeft: "5px" }}>({props.profile.ratingCount})</span>
                    </div>
                </Grid>
                {/* <Grid md={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                </Grid> */}
                <Grid md={12} style={{ display: "flex", flexDirection: "column", paddingTop: "24px" }}>
                    <Typography variant='body1' style={{ marginBottom: "30px" }}>
                        {props.profile.description}
                    </Typography>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Massages Offered</FormLabel>
                        <RadioGroup
                            value={selectedOfferIndex}
                            defaultValue={props.profile.offers[0].massageType}
                            onChange={handleSelectedOfferChange}
                        >
                            {props.profile.offers.map((offer, index) =>
                                <FormControlLabel value={index} control={<Radio />} label={elementifyOffer(offer)} />
                            )}
                        </RadioGroup>
                    </FormControl>
                    {/* <Button variant="contained">Book Now</Button> */}

                </Grid>
                <Grid xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticDateTimePicker className="booking-datetime-selector" ampmInClock slots={{ actionBar: () => <></> }} disablePast views={["month", "day", "hours", "minutes"]} onChange={handleDateTimeChange} value={selectedDateTime} label="Select a Date & Time" shouldDisableDate={(date) => handleShouldDisableDate(props.profile.availabilities, sundayZeroToMondayZeroBasedWeekday(date.day()))} shouldDisableTime={(time) => handleShouldDisableTime(props.profile.availabilities, time)} timeSteps={{ minutes: 30 }} />
                        <Button onClick={handleDateTimeConfirmation} style={{ width: "100%" }} variant="contained" disabled={!validateDateTimeSelection(selectedDateTime)}>Book</Button>
                    </LocalizationProvider>
                </Grid>

                <Grid xs={12}>
                    <ImageList cols={3}>
                        {props.profile.displayImages.map(imageURL => (
                            <ImageListItem>
                                <img src={imageURL} onClick={() => handleDisplayImageClick(imageURL)} style={{ cursor: "pointer" }} />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>
                {/* <Modal open={selectedDisplayImage != ""} onClose={handleDisplayImageModalClose} closeAfterTransition style={{ display: "flex", justifyContent: "center", alignItems: "center" }} slots={{ backdrop: Backdrop }} slotProps={{ backdrop: { timeout: 500 } }}>
                <Fade in={selectedDisplayImage != ""}>
                    <Box style={{position: "absolute"}}>
                        <img src={selectedDisplayImage} style={{ maxHeight: "90%", maxWidth: "90%" }} />
                    </Box>
                </Fade>
            </Modal> */}
                <Dialog open={invalidDateTimeDialogOpen} onClose={handleInvalidDateTimeDialogClose}>
                    <DialogTitle>
                        Invalid Date & Time
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant='body1'>
                            Please select an open availability slot.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleInvalidDateTimeDialogClose}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={bookDialogOpen} onClose={handleBookDialogClose} fullWidth maxWidth='md'>
                    <DialogTitle>
                        Booking with {props.profile.displayName}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Grid container sx={{ width: "90%" }} rowGap={3}>
                                <Grid xs={6} sm={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Typography variant='h5'>
                                        Type
                                    </Typography>
                                    <Typography variant='h6' sx={{ textAlign: "center" }}>
                                        <b>{formatMassageType(props.profile.offers[selectedOfferIndex]["massageType"])}</b>
                                    </Typography>

                                </Grid>
                                <Grid xs={6} sm={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Typography variant='h5'>
                                        Cost
                                    </Typography>
                                    <Typography variant='h6'>
                                        <b>${formatMassageType(props.profile.offers[selectedOfferIndex]["cost"])}</b>
                                    </Typography>
                                </Grid>
                                <Grid xs={6} sm={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Typography variant='h5'>
                                        When
                                    </Typography>
                                    <Typography variant='h6' sx={{ textAlign: "center" }}>
                                        <b>{selectedDateTime && selectedDateTime.format("dddd, MMMM D [a]t h:mm A")}</b>
                                    </Typography>
                                </Grid>
                                <Grid xs={6} sm={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Typography variant='h5'>
                                        Duration
                                    </Typography>
                                    <Typography variant='h6'>
                                        <b>{props.profile.offers[selectedOfferIndex]["duration"]} minutes</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        {/* <Typography variant='body1'>
                        {stringifyOffer(props.profile.offers[selectedOfferIndex])}
                    </Typography>
                    <Typography variant='body1'>
                        {selectedDateTime && selectedDateTime.format("dddd, MMMM D [a]t h:mm A")}
                    </Typography> */}
                        {stripeOptions.clientSecret ?
                            <Elements stripe={stripePromise} options={stripeOptions}>
                                <StripeCheckout profile={props.profile} offerIndex={selectedOfferIndex} epoch={selectedDateTime.unix()} />
                            </Elements> : <div>Loading payment...</div>}
                    </DialogContent>
                    <DialogActions>

                    </DialogActions>
                </Dialog>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={displayImageModalOpen}
                    onClose={handleDisplayImageModalClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}

                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                    <Fade in={displayImageModalOpen} style={{ outline: "none" }}>
                        <Box style={{ position: "absolute" }}>
                            <img src={selectedDisplayImage} style={{ maxWidth: "90vw", maxHeight: "90vh" }} />
                        </Box>
                    </Fade>
                </Modal></>}
            <Backdrop
                open={loadingBackropOpen}
            >
                <CircularProgress style={{ color: "white" }} />
            </Backdrop>
        </>
    )
}

export default Booking