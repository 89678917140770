import { useState, useEffect } from 'react';
import { Typography, Backdrop, CircularProgress, Rating, Button, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useNavigate, useParams } from 'react-router-dom';


function RateProvider(props){

    const navigate = useNavigate();

    const urlParams = useParams();

    const [profile, setProfile] = useState({});

    const [loadingBackropOpen, setLoadingBackdropOpen] = useState(false);

    const [rating, setRating] = useState(0);

    const [didSubmit, setDidSubmit] = useState(false);

    const handleRatingSubmit = () => {

        if(!rating)
        {
            alert("Please select a rating.");
            return;
        }

        const baseUrl = "https://func-bamboo-app-service.azurewebsites.net/api/SubmitRating?code=YFKomBdkOahdEZmwJXJLQaQPfwUAwaUSlDScnenuAeJeAzFubctQuA==";

        const url = `${baseUrl}&id=${urlParams.profileId}&epoch=${urlParams.bookingId}&rating=${rating}`;

        setLoadingBackdropOpen(true);

        fetch(url).then(response => {
            if(response.status == 200)
            {
                setDidSubmit(true);
            }
            else
            {
                alert("Something went wrong.");
            }
            setLoadingBackdropOpen(false);
        })
    }
    
    useEffect(() => {

        console.log(urlParams);

        if (urlParams.profileId) {
            const getProfilesUrlBase = "https://func-bamboo-app-service.azurewebsites.net/api/GetPublicProfiles?code=AcfJNoUbtb8MIa9RBOM0WSFV_HOkv0fhXIkvGq8V8UPsAzFuAXjHnw==";

            const url = `${getProfilesUrlBase}&id=${urlParams.profileId}`;

            setLoadingBackdropOpen(true);

            fetch(url).then(response => {
                if (response.status == 200) {
                    response.json().then(json => {
                        setProfile(json);
                    })
                }
                setLoadingBackdropOpen(false);
            })
        }
    }, [])


    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Grid container style={{ maxWidth: "1000px", width: "90%", marginTop: "30px", display: "flex", justifyContent: "left" }} spacing={3}>
                {didSubmit ? <><Grid xs={12}>
                    <Typography variant='h3'>
                        Thanks for rating {profile.displayName}!
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Button sx={{width: "400px", height: "40px"}} variant='contained' onClick={() => navigate('/')}>Book another massage</Button>
                </Grid>
                </> : <>
                <Grid xs={12}>
                    <Typography variant='h3'>
                        Rate {profile.displayName}
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Divider />
                </Grid>
                <Grid xs={12}>
                    <Rating value={rating} onChange={(event, newValue) => setRating(newValue)} sx={{transform: "scale(3)", transformOrigin: "top left"}} precision={.5} />
                </Grid>
                <Grid xs={12} sx={{marginTop: "50px"}}>
                    <Button onClick={handleRatingSubmit} variant='contained'>Submit</Button>
                </Grid></>}
                </Grid>
            <Backdrop
                open={loadingBackropOpen}
            >
                <CircularProgress style={{ color: "white" }} />
            </Backdrop>

        </div>
    )
}

export default RateProvider
