import { useState, useEffect, useRef, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import { AppBar, Button, Paper, FormControl, InputLabel, Select, MenuItem, ListItemText, OutlinedInput, Checkbox, Box, Chip, Card, CardHeader, Avatar, CardMedia, CardContent, Typography, Rating, CardActionArea, Backdrop, CircularProgress, CardActions, Divider } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import Booking from './Booking'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import CheckIcon from '@mui/icons-material/Check';


const MASSAGE_TYPES = [
  "swedish",
  "deep-tissue",
  "prenatal",
  "hot-stone",
  "aromatherapy",
  "trigger-point",
  "reflexology",
  "thai",
  "myofascial"
]

const CITIES = [
  "Calgary"
]

const getFutureLocaleDate = () => {
  const now = new Date();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based; adding 1 and padding with 0 if necessary
  const day = (now.getDate() + 2).toString().padStart(2, '0');
  const year = now.getFullYear();
  return `${month}-${day}-${year}`;
}

function convertDateToDayOfWeek(dateStr) {
  // Split the date string to get the month, day, and year
  const [month, day, year] = dateStr.split("-");

  // Create a new Date object using the year, month (adjusted for zero-based indexing), and day
  const date = new Date(year, month - 1, day);

  // Use getDay() to get the day of the week as a number from 0 (Sunday) to 6 (Saturday)
  let dayOfWeek = date.getDay();

  // Adjust dayOfWeek to make Monday 0, ..., Sunday 6
  dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

  return dayOfWeek;
}
function App() {

  const navigate = useNavigate();

  const urlParams = useParams();

  console.log(urlParams.profileId);

  const [searchFilter, setSearchFilter] = useState({ "city": CITIES[0], "massageTypes": [MASSAGE_TYPES[0]], "availability": getFutureLocaleDate() });

  const [loadingBackropOpen, setLoadingBackdropOpen] = useState(false);

  const isFirstRender = useRef(true);

  const [profiles, setProfiles] = useState();

  const [selectedProfile, setSelectedProfile] = useState();

  const [massageTypeSelectOpen, setMassageTypeSelectOpen] = useState(false);

  const handleProfileClick = (profile) => {
    setSelectedProfile(profile);
    navigate("/" + profile.id);
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // toggle flag after first render/mounting
      return;
    }
    if (!urlParams.profileId) {
      loadProfiles();
    }
  }, [searchFilter])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [selectedProfile])

  useEffect(() => {

    console.log(urlParams);

    if (urlParams.profileId) {
      const getProfilesUrlBase = "https://func-bamboo-app-service.azurewebsites.net/api/GetPublicProfiles?code=AcfJNoUbtb8MIa9RBOM0WSFV_HOkv0fhXIkvGq8V8UPsAzFuAXjHnw==";

      const url = `${getProfilesUrlBase}&id=${urlParams.profileId}`;

      setLoadingBackdropOpen(true);

      fetch(url).then(response => {
        if (response.status == 200) {
          response.json().then(json => {
            setSelectedProfile(json);
          })
        }
        setLoadingBackdropOpen(false);
      })
    }
  }, [])

  const loadProfiles = () => {

    setLoadingBackdropOpen(true);

    // Define the URL of your Azure Function endpoint
    const functionUrl = 'https://func-bamboo-app-service.azurewebsites.net/api/GetPublicProfiles?code=AcfJNoUbtb8MIa9RBOM0WSFV_HOkv0fhXIkvGq8V8UPsAzFuAXjHnw==';

    // Construct the full URL with the document ID as a query parameter
    const urlWithParams = `${functionUrl}&city=${searchFilter.city}&availability=${convertDateToDayOfWeek(searchFilter.availability)}&massageTypes=${searchFilter.massageTypes.join(",")}`;

    // Use the fetch API to send the request
    fetch(urlWithParams, {
      method: 'GET', // Specify the request method
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch document');
        }
        return response.json(); // Parse the response body as JSON
      })
      .then(data => {
        // The data object now contains the Cosmos DB document
        console.log('Document fetched successfully:', data);
        setProfiles(data);
        setLoadingBackdropOpen(false);
        // You can now use the data object as needed in your application
        // For example, store it in a variable or state if using a framework like React
      })
      .catch(error => {
        console.error('Error:', error);
        setLoadingBackdropOpen(false);
      });
  }


  const findLowestCostOffer = (profile) => {
    if (!profile.offers || !Array.isArray(profile.offers) || profile.offers.length === 0) {
      return null; // Returns null if 'offers' is not an array or it's empty
    }

    return profile.offers.reduce((lowest, offer) => {
      const currentCost = parseFloat(offer.cost.replace(/[^0-9.-]+/g, ""));
      const lowestCost = parseFloat(lowest.cost.replace(/[^0-9.-]+/g, ""));
      return currentCost < lowestCost ? offer : lowest;
    });
  }

  const handleCityFilterChange = (e) => {
    setSearchFilter({ ...searchFilter, "city": e.target.value });
  }

  const formatMassageType = (str) => {
    //debugger;
    // Replace all '-' with spaces
    let formattedStr = str.replace(/-/g, ' ');

    // Capitalize the first letter
    formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);

    formattedStr += formattedStr == "Swedish" ? " (Relaxation)" : "";

    return formattedStr;
  }

  const handleMassageTypeSelectOpen = () => {
    setMassageTypeSelectOpen(true);
  }

  const handleMassageTypeSelectClose = () => {
    setMassageTypeSelectOpen(false);
  }

  const handleMassageTypeFilterChange = (e) => {
    setSearchFilter({ ...searchFilter, "massageTypes": e.target.value })
    setMassageTypeSelectOpen(false);
  }

  const handleAvailabilityFilterChange = (newValue) => {
    setSearchFilter({ ...searchFilter, "availability": newValue.format("MM-DD-YYYY") })
  }

  const weekdayIdToName = (id) => {
    const weekdays = ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays", "Sundays"];

    return weekdays[id];
  }

  const convertMilitaryTimeToTwelveHour = (militaryTime) => {
    // Validate input
    if (!/^\d{4}$/.test(militaryTime)) {
      throw new Error('Invalid time format. Please use a 4-digit military time format.');
    }

    // Extract hours and minutes from the input
    const hours = parseInt(militaryTime.substring(0, 2), 10);
    const minutes = militaryTime.substring(2);

    // Convert hours from 24-hour to 12-hour format
    const twelveHour = hours % 12 || 12;
    // Determine AM or PM
    const amPm = hours < 12 ? "AM" : "PM";

    // Format the time in 12-hour format
    return `${twelveHour}:${minutes} ${amPm}`;
  }


  const stringifyAvailability = (availability) => {

    //Example: '0,2 1230-1530' should map to 'Monday and Wednesday from 12:30pm to 3:30pm'

    let result = "";

    let days = availability.daysOfWeek;

    for (let dayId of days) {
      result += weekdayIdToName(dayId) + (days.indexOf(dayId) === days.length - 2 ? " and " : (days.indexOf(dayId) === days.length - 1 ? "" : ", "));
    }

    result += ` from ${convertMilitaryTimeToTwelveHour(availability.fromTime)} to ${convertMilitaryTimeToTwelveHour(availability.toTime)}`;

    return result;

  }


  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Grid container style={{ maxWidth: "1000px", width: "90%", marginTop: "30px", display: "flex", justifyContent: selectedProfile ? "center" : "left" }} spacing={3}>

        <Grid md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img style={{ cursor: "pointer" }} src="/Bamboo-Logo-Dark.png" width={300} onClick={() => { navigate("/"); setSelectedProfile(undefined); loadProfiles(); }} /><span style={{ fontSize: "40px", marginLeft: "-15px" }}>&#8482;</span>
        </Grid>
        <Grid md={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography variant='h5' sx={{fontSize: "25px"}} color='#042d3d'>Are you a massage therapist? <a href="https://provider.bamboomassage.ca">Click here</a></Typography>
        </Grid>
        <Grid xs={12} sx={{ backgroundImage: selectedProfile ? `url(${selectedProfile.displayImages[0]})` : 'url(/WomanMassage.jpg)', margin: "12px", height: "300px", backgroundSize: "cover", backgroundPosition: "center", borderRadius: "5px", color: "white" }}>
          <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "5px", borderRadius: "5px" }}>
            <Typography variant='h5'>
              Need an in-home massage?
            </Typography>
            <Typography variant='body1'>
              Book a massage therapist to help you relax at home!
            </Typography>
          </div>

        </Grid>
        {selectedProfile ? <Booking profile={selectedProfile} BackButton={() => <Button variant='outlined' style={{ marginLeft: "5px" }} onClick={() => { navigate("/"); setSelectedProfile(undefined); loadProfiles(); }}>New Search</Button>} /> :
          <Fragment>
            <Grid xs={12} sx={{ marginTop: "-56px", padding: "24px" }}>
              <Paper style={{ width: "100%" }}>
                <Grid container spacing={2} style={{ padding: "20px" }}>
                  <Grid xs={12} sm={3}>
                    <FormControl fullWidth>
                      <InputLabel>City</InputLabel>
                      <Select
                        value={searchFilter.city}
                        label="City"
                        onChange={handleCityFilterChange}
                      >
                        {CITIES.map(city => <MenuItem value="Calgary">Calgary</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Massage Type</InputLabel>
                      <Select
                        value={searchFilter.massageTypes}
                        label="Massage Type"
                        multiple
                        open={massageTypeSelectOpen}
                        onOpen={handleMassageTypeSelectOpen}
                        onClose={handleMassageTypeSelectClose}
                        input={<OutlinedInput label="Massage Type" />}
                        renderValue={(selected) => <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={formatMassageType(value)} />
                          ))}
                        </Box>}
                        onChange={handleMassageTypeFilterChange}
                      >
                        {MASSAGE_TYPES.map(massageType =>
                          <MenuItem value={massageType}>
                            <Checkbox checked={searchFilter.massageTypes.indexOf(massageType) > -1} />
                            <ListItemText primary={formatMassageType(massageType)} />
                          </MenuItem>

                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker sx={{ width: "100%" }} views={['month', 'day']} label='Available' onChange={handleAvailabilityFilterChange} value={dayjs(searchFilter.availability)} disablePast />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {profiles && profiles.length > 0 ?
              <div style={{ padding: "12px", width: "100%" }}>
                {profiles.map(profile => (
                  <Grid xs={12} sm={6} md={3}>
                    <Card sx={{ height: "100%" }}>
                      <CardActionArea onClick={() => handleProfileClick(profile)} sx={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        <CardHeader avatar={
                          <Avatar src={profile.profilePicture} />
                        } title={profile.displayName} />
                        <CardMedia component="img" height={194} image={profile.displayImages[0]} />
                        <CardContent>
                          <div style={{ display: "inline-flex", alignItems: "center" }}>
                            <Rating value={profile.rating} readOnly precision={0.5} />
                            <span style={{ marginLeft: "5px" }}>({profile.ratingCount})</span>
                          </div>
                          <Typography variant='body1'>
                            <br />
                            <br />
                          </Typography>
                          <Typography variant="body1">{profile.pitch}</Typography>
                          <br />
                          <br />
                        </CardContent>
                        <CardActions sx={{ marginTop: "auto", padding: "16px" }}>
                          <Typography variant='body1'>
                            <b>From ${findLowestCostOffer(profile).cost}</b>
                          </Typography>
                        </CardActions>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}</div> : <Grid xs={12} sx={{ padding: "24px" }}><Typography variant='body1'>No availabilities</Typography></Grid>}

          </Fragment>}
        <Grid xs={12}>
          <Divider sx={{ marginTop: "6rem", marginBottom: "6rem" }} />
        </Grid>
        <Grid xs={12} sx={{ margin: "12px", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Typography variant='h4' sx={{ marginBottom: "1rem" }}>What is Bamboo&#8482;?</Typography>
            <Typography variant='body1' textAlign='center' lineHeight={2} fontSize={25}>
              Bamboo&#8482; is an online marketplace that connects you to local, affordable massage therapists. Find massage therapists that meet your needs when you sort search results by location, massage types offered, and availability.
              <div style={{ marginTop: "1rem" }}>
                On your massage booking day, your massage therapist will meet you at your door. Leave the massage and relaxation to them.
              </div>
              <div style={{ marginTop: "1rem" }}>
                Customize your booking to fit your massage needs. Book on Bamboo&#8482; by the half hour, hour, hour and half, or two hours to get just the right amount of massage.
              </div>
            </Typography>
            <Divider sx={{ marginTop: "6rem", marginBottom: "6rem", width: "100%" }} />
            <Typography variant='h4' sx={{ marginBottom: "3rem" }}>Why Choose Bamboo&#8482;?</Typography>
            <Typography variant='body1' lineHeight={2} fontSize={25}>
              <Grid container spacing={1}>
                <Grid md={12} sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIcon fontSize='large' fontWeight={700} color="success" sx={{ marginRight: "5px" }} />Backed by a commitment to professional in-home massage therapy
                </Grid>
                <Grid md={12} sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIcon fontSize='large' color="success" sx={{ marginRight: "5px" }} />Transparent and guaranteed upfront pricing for all services
                </Grid>
                <Grid md={12} sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIcon fontSize='large' color="success" sx={{ marginRight: "5px" }} />Access to a multitude of unedited customer reviews for genuine feedback
                </Grid>
                {/* <Grid md={12}>
                  <CheckIcon fontSize='large' color="success" />Thousands of successful sessions completed, highlighting our experience and customer satisfaction
                </Grid> */}
                <Grid md={12} sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIcon fontSize='large' color="success" sx={{ marginRight: "5px" }} />Massage therapists only get paid once you are satisfied
                </Grid>
              </Grid>
            </Typography>
            <Divider sx={{ marginTop: "6rem", marginBottom: "6rem", width: "100%" }} />
            <Typography variant='h4' sx={{ marginBottom: "1rem" }}>How Bamboo&#8482; Works</Typography>
            <SearchIcon fontSize='large' color="primary" /><Typography variant='h4'>Search</Typography>
            <Typography variant='body1' sx={{ marginBottom: "3rem" }} lineHeight={2} textAlign='center' fontSize={25}>
              Input your location to discover Bamboo&#8482; therapists in your area. Filter your options by massage types offered and availability, and select a therapist that aligns with your specific massage needs and budget.
            </Typography>
            <StarIcon fontSize='large' color="secondary" /><Typography variant='h4'>Schedule</Typography>
            <Typography variant='body1' sx={{ marginBottom: "3rem" }} lineHeight={2} textAlign='center' fontSize={25}>
              Book your desired service.
            </Typography>
            <SentimentSatisfiedAltIcon fontSize='large' color="success" /><Typography variant='h4'>Relax</Typography>
            <Typography variant='body1' sx={{ marginBottom: "10rem" }} lineHeight={2} textAlign='center' fontSize={25}>
              Enjoy a professional massage experience in the tranquility of your own home. Once you're fully satisfied with the service provided, use the Bamboo&#8482; Payment Code to finalize payment to your therapist, assuring a secure and satisfactory transaction.
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Backdrop
        open={loadingBackropOpen}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    </div>
  );
}

export default App;
