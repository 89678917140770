import { Button, TextField } from "@mui/material";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

function StripeCheckout(props){

    const stripe = useStripe();

    const elements = useElements();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [isProcessingPayment, setIsProcessingPayment] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const handleBookingSubmit = (data) => {
        //e.preventDefault();

        console.log(JSON.stringify(data));

        if (!stripe || !elements) {
            return;
        }

        setIsProcessingPayment(true);

        stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `https://func-bamboo-app-service.azurewebsites.net/api/ConfirmBooking?code=V0qG-oE5uVv0Zfgqgn4r1JkpUxZT0172-t_8-s0eFdREAzFun7OAlA==&id=${props.profile["id"]}&offer=${props.offerIndex}&epoch=${props.epoch}&name=${data["name"]}&location=${data["address"] + ", " + data["city"] + ", " + data["province"]}&notes=${data["notes"] ? data["notes"] : "No notes."}&email=${data["email"]}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
                receipt_email: data["email"]
            },
        }).then(response => {
            setIsProcessingPayment(false);
            if(response.error.type != "validation_error")
            {
                setErrorMessage(response.error.message);
            }
        })

    }

    return (
        <form onSubmit={handleSubmit((data) => handleBookingSubmit(data))} style={{marginTop: "20px"}}>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <TextField error={errors.name} label="Full Name" placeholder="John Doe" {...register("name", { required: true, maxLength: 30, minLength: 2 })} fullWidth />
                </Grid>
                <Grid xs={12}>
                    <TextField error={errors.email} label="Email" placeholder="john@email.com" {...register("email", { required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i })} fullWidth />
                </Grid>
                <Grid xs={12}>
                    <TextField error={errors.address} label="Address" placeholder="123 ABC Lane SW" {...register("address", {required: true})} fullWidth />
                </Grid>
                <Grid xs={12}>
                    <TextField error={errors.city} label="City" placeholder="Calgary" {...register("city", {required: true})} fullWidth />
                </Grid>
                <Grid xs={12}>
                    <TextField error={errors.province} label="Province" placeholder="Calgary" {...register("province", { required: true })} fullWidth />
                </Grid>
                <Grid xs={12}>
                    <TextField error={errors.notes} label="Notes" placeholder="" {...register("notes")} fullWidth multiline />
                </Grid>
                <Grid xs={12}>
                    <PaymentElement />
                </Grid>
                <Grid xs={12}>
                    <p style={{display: errorMessage ? "block" : "none", color: "red"}}>{errorMessage}</p>
                </Grid>
                <Grid xs={12} style={{display: "flex", justifyContent: "left"}}>
                    <Button type="submit" disabled={isProcessingPayment} variant="contained" style={{width: "100px"}}>Book</Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default StripeCheckout
